/***** Custom reset *****/
	*, *:before, *:after {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	html, body {
		width: 100%;
		//height: 100%;
		//min-height: 100%;
	}


/***** Imports *****/
	@import 'fonts';
	@import 'general';


/***** Basic styling *****/
	$tile-size: 30px;

	body {
		position: relative;
        font-family: 'Myriad Pro', serif;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.5;
		background: $white;
		color: $text;
		//padding-top: (4.5 * $tile-size);
padding-top: (3 * $tile-size);
		overflow-y: scroll;
	}

	.wrapper {
		display: block;
		position: relative;
		margin: 0 auto;
		width: 100%;
		max-width: 1020px;
	}


/***** Logo *****/
	#logo-outer {
		top: 0px;
		left: 0px;
		position: absolute;
		z-index: 11;
		width: 100%;
		height: (3 * $tile-size);
		display: inline-block;
		background-color: $green_10;
		border-bottom: 4px solid $green;

		#logo-inner {
			//
			
			#logo {
				position: absolute;
				top: $tile-size;
				left: 50%;
				width: (10 * $tile-size);
				margin-left: (-5 * $tile-size);
				height: (2 * $tile-size);
				line-height: 1;

				&:before, &:after {
					content: '';
					display: inline-block;
					position: absolute;
					background-color: $green;
					top: 0px;
					left: 50%;
					margin-left: -1px;
					width: 2px;
					height: (2 * $tile-size);
					z-index: 2;
				}
				&:after {
					top: 50%;
					left: 50%;
					margin-left: (-1 * $tile-size);
					width: (2 * $tile-size);
					height: 2px;
					z-index: 2;
				}
				
				img {
					position: absolute;
					top: 0px;
					left: 50%;
					margin-left: (-1 * $tile-size);
					width: (2 * $tile-size);
					height: (2 * $tile-size);
					border: 4px solid $green;
					background-color: #fff;
					z-index: 1;
				}
				span {
					position: absolute;
					bottom: 3px;
					font-size: 22px;
					text-transform: uppercase;
					color: $purple;
					letter-spacing: 1.5px;
				}
				span:nth-child(2) {
					right: 50%;
					margin-right: ($tile-size + 4px);
					font-weight: normal;
				}
				span:nth-child(3) {
					left: 50%;
					margin-left: ($tile-size + 6px);
					font-weight: bold;
				}
			}

			span.ornament {
				width: $tile-size;
				height: $tile-size;
				display: inline-block;
				background-color: #fff;
				border: 2px solid $green;
				position: absolute;
				top: $tile-size;

				&:nth-child(2) {
					left: $tile-size;
				}
				&:nth-child(3) {
					left: 3 * $tile-size;
				}
				&:nth-child(4) {
					left: 5 * $tile-size;
				}
				&:nth-child(5) {
					right: $tile-size;
				}
				&:nth-child(6) {
					right: 3 * $tile-size;
				}
				&:nth-child(7) {
					right: 5 * $tile-size;
				}

				@media all and (max-width: 799px) {
					&:nth-child(2) {
						width: 1.5 * $tile-size;
						height: 1.5 * $tile-size;
						top: 0.75 * $tile-size;
						left: 0.75 * $tile-size;
					}
					&:not(:nth-child(2)) {
						display: none;
					}
				}
			}
		}
	}


/***** Menu *****/
/*
	top: $tile-size;
	width: $tile-size;
	height: $tile-size;
	background-color: #fff;
	border: 2px solid $green;
 */
	a.menu-toggle {
		//
		display: none;
	}

	#menu-outer {
		top: 1.5 * $tile-size;
		left: 0px;
		position: absolute;
		z-index: 10;
		width: 100%;
		height: 1.5 * $tile-size;
		display: inline-block;
		background-color: $green;
		transition: top 0.5s ease;

		&.over {
			top: 3 * $tile-size;
		}

		#menu-inner {

			#menu {
				width: 100%;
				display: inline-block;
				position: relative;

				ul, li {
					list-style: none;
					line-height: 0;
					font-size: 0;
				}

				> li.menu-item {
					width: 100%;
					display: inline-block;

					> ul.menu {
						width: 100%;
						display: -moz-flex;
						display: -webkit-flex;
						display: flex;
						-moz-justify-content: space-around;
						-webkit-justify-content: space-around;
						justify-content: space-around;
						text-align: justify;
						
						> li.menu-item {
							display: inline-block;

							> a {
								display: inline-block;
								line-height: 1;
								font-size: 18px;
								height: 45px;
								padding: 14px 15px 13px;
								text-decoration: none;
								color: $purple;
								color: darken($green, 20%);

								&:hover, &:focus {
									color: #fff;
								}
							}
						}
					}
				}
			}

		}
	}


/***** Content *****/
	.page {
		position: relative;
		// display: block;
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: flex-start;
		align-content: flex-start;
		width: 100%;
		max-width: 1020px;
		margin: 0px auto;

		> span.spacer {
			display: none !important;
			
			@media all and (min-width: 400px) {
				content: '';
				position: relative;
				float: left;
				clear: both;
				display: inline-block;
				width: 100%;
				//min-height: (3 * $tile-size);
				min-height: (2 * $tile-size);
			}
		}
	}

	#background_picture {
		position: relative;
		width: 100%;
		height: 350px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

	    > h1 {
			position: absolute;
			bottom: 40px;
			right: 50%;
			margin-right: -485px;
			color: #fff;
			font-size: 70px;
			font-weight: 700;
			text-transform: uppercase;
			text-shadow: 0px 1px 10px rgba(0,0,0,0.5), 0px 1px 30px rgba(0,0,0,0.3);
	    }
	    @media all and (max-width: 1019px) {
			> h1 {
				right: 25px;
				margin-right: 0px;
			}
	    }
	    @media all and (max-width: 799px) {
			> h1 {
				font-size: 60px;
			}
		}
	    @media all and (max-width: 499px) {
			> h1 {
				font-size: 40px;
			}
		}
	}

	#footer-outer {
		//bottom: 0px;
		//left: 0px;
		//position: fixed;
		position: relative;
		z-index: 10;
		width: 100%;
		//min-height: (3 * $tile-size);
		min-height: (2 * $tile-size);
		display: inline-block;
		background-color: $green_10;
		border-top: 4px solid $green;

		@media all and (max-width: 399px) {
			position: relative;
		}
		
		#footer-inner {
			text-align: center;
			//padding: 20px;
			padding: 15px 20px;
			font-size: 0px;
			line-height: 0;

			@media all and (min-width: 800px) {
				// padding: 20px (3 * $tile-size);
				padding: 15px (3 * $tile-size);

				&:before, &:after {
					content: '';
					position: absolute;
					//top: $tile-size;
					top: 0.5 * $tile-size;
					width: $tile-size;
					height: $tile-size;
					background-color: #fff;
					border: 2px solid $green;
				}
				&:before {
					left: $tile-size;
				}
				&:after {
					right: $tile-size;
				}
			}
			
			span {
				span {
					font-size: 14px;
					line-height: 1;
					display: inline-block;
					padding: 5px;

					&:after {
						content: '|';
						display: inline-block;
						padding-left: 10px;
					}

					&:last-child:after {
						display: none;
					}
				}
			}

			a.tel, a.web, a.email {
				color: $text;
				position: relative;
				padding-left: 15px;
				text-decoration: none;

				&:before {
					content: '';
					position: absolute;
					top: 1px;
					left: 0px;
					color: $purple;
					font-size: 13px;
					font-weight: bold;
				}
			}
			a.tel:before {
				content: 'T';
			}
			a.web:before {
				content: 'W';
			}
			a.email:before {
				content: 'E';
			}
		}
	}

	#vhw {
		width: auto;
		position: fixed;
		bottom: 10px;
		left: 100%;
		margin-left: -28px;
		transform: translate(0%, 0%);
		height: 24px;
		white-space: nowrap;
		color: rgba(#000, 0.3);
		text-decoration: none;
		transition: all 0.35s ease;
		padding-right: 5px;
		z-index: 20;

		img {
			margin-right: 5px;
			border: 0px;
			vertical-align: -30%;
			opacity: 0.3;
		}

		&:hover, &:focus {
			//margin-left: 0px;
			transform: translate(-100%, 0%);
			color: rgba(#000, 0.5);
			// color: $purple;
			
			img {
				opacity: 0.5;
			}
		}
	}


/***** Modules *****/
	#mod_contact {
		#go_mod_contact {
			position: absolute;
			opacity: 0;
			left: -2000px;
		}
	}


/***** Status & Form *****/
    .statusbox {
        width: 100%;
        display: inline-block;
        position: relative;
		margin-bottom: 25px;
    	padding: 25px;
    	padding-left: 65px;
        border: 2px solid #888;
        color: #888;

		&:before {
			content: '\f05a'; // info
			border-color: inherit;
			color: inherit;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: 24px;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 24px;
			position: absolute;
			left: 35px;
			top: 50%;
			transform: translate(-50%, -50%);
		}

        &.help {
            border: 2px solid #3574B2;
            color: #3574B2;
            &:before {
				content: '\f059';
            }
        }
        &.ok {
            border: 2px solid #4CAF50;
            color: #4CAF50;
            &:before {
				content: '\f058';
            }
        }
        &.warning {
            border: 2px solid #FB8C00;
            color: #FB8C00;
            &:before {
				content: '\f06a';
            }
        }
        &.error {
            border: 2px solid #D32F2F;
            color: #D32F2F;
            &:before {
				content: '\f057';
            }
        }
    }


    input, select, textarea, button, .button {
        &:not([class*="cke_"]) {
            display: inline-block;
            padding: 10px 15px;
            font-size: 1.1rem;
            outline: none;
            vertical-align: top;
            color: rgba(0,0,0, 0.54);
        }
        &[type="file"] {
            background: #fff;
        }
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), select, textarea {
        &:not([class*="cke_"]) {
            width: 100%;
        }
    }
    textarea {
        &:not([class*="cke_"]) {
           height: 85px;
       }
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), select, textarea {
        &:not([class*="cke_"]) {
            border: 1px solid rgba(0,0,0, 0.3);
            box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3);

            &:focus {
            	padding: 10px 15px;
                border: 1px solid rgba(#1E88E5, 1);
                box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3),
                    0px 0px 2px 1px rgba(#03A9F4, 0.8);
            }
        }
    }

    input[type="checkbox"], input[type="radio"] {
        width: 0px;
        height: 0px;
        opacity: 0;
        padding: 0px;
        margin: 0px;

        & + label {
            display: inline-block;
            position: relative;
            height: 20px;
            padding-left: 30px;
            font-size: 1.01rem;
            outline: none;
            margin-right: 30px;

            &:before {
                content: '';
                position: absolute;
                z-index: 2;
                top: 0px;
                left: 0px;
                width: 20px;
                height: 20px;
                background: #fff;
                border: 1px solid rgba(0,0,0, 0.3);
                border-radius: 3px;
                box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3);
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 3;
                opacity: 0;
                transition: all 0.15s ease 0s;
                top: 2px;
                left: 7px;
                width: 7px;
                height: 13px;
                border-bottom: 3px solid $purple;
                border-right: 3px solid $purple;
                transform: rotate(45deg);
                transform-origin: 50% 50%;
            }
        }
        &[type="radio"] + label {
            &:before, &:after {
                border-radius: 50%;
            }
            &:after {
                top: 5px;
                left: 5px;
                width: 10px;
                height: 10px;
                border: 0px;
                transform: rotate(0deg);
                transform-origin: 50% 50%;
                background: $purple;
            }
        }

        &:hover + label {
            //
        }

        &:focus + label {
            &:before {
                border: 1px solid rgba(#1E88E5, 1);
                box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3),
                    0px 0px 2px 1px rgba(#03A9F4, 0.8);
            }
        }

        &[type="checkbox"]:checked + label:after {
            opacity: 1;
        }

        &[type="radio"]:checked + label:after {
            opacity: 1;
        }
    }

    button, .button, input[type="submit"] {
        &:not([class*="cke_"]) {
            position: relative;
            cursor: pointer;
            background: #F5F5F5;
            color: rgba(0,0,0, 0.54);
            border: 1px solid rgba(0,0,0, 0.1);
            box-shadow: inset 0px 1px 0px 0px rgba(255,255,255, 0.3), 0px 1px 3px -1px rgba(0,0,0, 0.4);
            border-radius: 3px;
            text-decoration: none;
            padding: 12px 15px;
            height: 45px;

            &:hover, &:focus {
                background: #FAFAFA;
            }
            &:active {
                background: #E0E0E0;
                padding-top: 13px;
                padding-bottom: 11px;
                box-shadow: inset 0px 1px 1px 0px rgba(0,0,0, 0.1);

                .fa {
                    margin-top: -9px;
                }
            }

            &.ico {
                padding-left: 37px;
            }
            &.yellow {
                background: #FFA726;

                &:hover, &:focus {
                    background: #FFB74D;
                }
                &:active {
                    background: #FB8C00;
                }
            }
            &.light-blue {
                background: #B3E5FC;

                &:hover, &:focus {
                    background: #E1F5FE;
                }
                &:active {
                    background: #4FC3F7;
                }
            }
            &.add {
                background: #4CAF50;

                &:hover, &:focus {
                    background: #66BB6A;
                }
                &:active {
                    background: #388E3C;
                }
            }
            .fa {
                position: absolute;
                top: 50%;
                margin-top: -10px;
                left: 12px;
                display: inline-block;
                width: 20px;
                height: 20px;
                font-size: 20px;
            }
        }
    }

    .form-label {
        display: inline-block;
        width: 100%;
        padding: 10px 0px 5px;
        font-size: 1.1rem;
       
        &.error {
            color: #D32F2F;
        }
    }

    .form-input {
        display: inline-block;
        width: 100%;
        padding: 5px 0px 20px;
    }


/***** Responsiveness *****/
	@media all and (max-width: 1023px) {

		/*
		img {
			max-width: 100%;
			height: auto !important;
		}
		*/

		#menu-outer {
			//
			
			#menu-inner {
				//
				
				#menu {
					//
				}
			}
		}

	}
	@media all and (max-width: 799px) {
		body {
			padding-top: 3 * $tile-size;
			
			#logo-outer {
				position: fixed;
				z-index: 12;
			}

			a.menu-toggle {
				display: inline-block;
				position: fixed;
				width: 1.5 * $tile-size;
				height: 1.5 * $tile-size;
				top: 0.75 * $tile-size;
				right: 0.75 * $tile-size;
				background-color: #fff;
				border: 2px solid $green;
				z-index: 13;

				&:before, &:after {
					content: '';
					position: absolute;
					border-style: solid;
					border-color: inherit;
					border-width: 0px;
					border-top-width: 3px;
					top: 19px;
					left: 8px;
					width: 25px;
					height: 3px;
					transform-origin: 50% 50%;
					transition: left 0.35s ease, width 0.35s ease, transform 0.35s ease;
				}

				&:before {
					transform: translate(0%, -9px) rotate(0deg);
				}

				&:after {
					transform: translate(0%, 9px) rotate(0deg);
				}

				&.open {
					i {
						opacity: 0;
					}
					&:before {
						left: 4px;
						width: 33px;
						transform: translate(0%, 0px) rotate(-45deg);
					}
					&:after {
						left: 4px;
						width: 33px;
						transform: translate(0%, 0px) rotate(45deg);
					}
				}

				i {
					position: absolute;
					top: 19px;
					left: 8px;
					width: 25px;
					height: 3px;
					border-style: solid;
					border-color: inherit;
					border-width: 0px;
					border-top-width: 3px;
					opacity: 1;
					transition: opacity 0.35s ease;
				}

				&:hover, &:focus {
					border-color: $purple;
				}
			}

			#menu-outer {
				z-index: 11;
				background-color: transparent;

				#menu-inner {
					max-width: 100%;

					&:before {
						content: '';
						position: fixed;
						display: inline-block;
						top: 3 * $tile-size;
						bottom: 0px;
						left: 0px;
						right: 0px;
						background-color: darken($green, 20%);
						background-color: $green;
						opacity: 0;
						transition: opacity 0.35s ease;
						pointer-events: none;
					}

					#menu {
						position: fixed;
						top: 3 * $tile-size;
						bottom: 0px;
						right: -300px;
						width: 100%;
						max-width: 300px;
						background-color: $green_10;
						transition: right 0.35s ease, box-shadow 0.35s ease;
						overflow-y: scroll;
						border-left: 4px solid $green;
						
						ul.menu, li.menu-item {
							display: inline-block !important;
							width: 100%;
							float: left;

							a {
								float: left;
								width: 100%;
								padding: 15px 15px 13px 25px !important;

								&:hover, &:focus {
									color: $purple !important;
									background-color: #fff;
								}
							}
						}

						ul ul {
							padding-left: 15px;
						}
					}

					&.open {
						
						&:before {
							display: inline-block;
							opacity: 0.6;
							pointer-events: all;
						}

						#menu {
							right: -20px;
						}
					}
				}
			}
		}
	}

	@media all and (max-width: 499px) {
		body {
			padding-top: (3 * $tile-size) + 15px;

			#logo-outer {
				height: (3 * $tile-size) + 15px;

				#logo-inner {
					#logo {
						top: $tile-size + 15px;
					}
				}
			}

			#menu-outer {
				#menu-inner {
					&:before {
						top: (3 * $tile-size) + 15px;
					}
					#menu {
						top: (3 * $tile-size) + 15px;
					}
				}
			}
		}
	}
